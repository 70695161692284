import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AuthState, AuthStatus, Guest, Token, User, UserPreference, UserSecurity, UserSiteDomain } from "./types";

const getInitialState = (): AuthState => {
  return {
    status: AuthStatus.NA,
    user: undefined,
    guest: undefined,
    token: undefined,
    userSecurity: undefined,
    userPreference: undefined,
    siteDomain: undefined,
  };
};

export const authReducerSlice = createSlice({
  name: "auth",
  initialState: getInitialState(),
  reducers: {
    reset(state) {
      Object.assign(state, getInitialState());
    },
    setStatus(state, action: PayloadAction<AuthStatus>) {
      state.status = action.payload;
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setGuest(state, action: PayloadAction<Guest>) {
      state.guest = action.payload;
    },
    setToken(state, action: PayloadAction<Token>) {
      state.token = action.payload;
    },
    setUserPreference(state, action: PayloadAction<UserPreference>) {
      state.userPreference = action.payload;
    },
    setUserSecurity(state, action: PayloadAction<UserSecurity>) {
      state.userSecurity = action.payload;
    },
    setSiteDomain(state, action: PayloadAction<UserSiteDomain>) {
      state.siteDomain = action.payload;
    },
  },
});
