import { ALL_AVAILABLE_TIMEZONES_CHOICES } from "@/core/constants";
import { OnboardingQuestionType, OnboardingStepType, Step } from "./types";

// !FIXME - this needs to be moved to the Backend

export const getDefaultSteps = (): Step[] => {
  return [
    {
      id: "static",
      slug: "static",
      type: OnboardingStepType.STATIC,
      title: "Specialistul în sănătate mentală pe care îl cauți este aici!",
      description:
        "Dacă ai ajuns pe această pagină ești foarte aproape de a întâlni online un specialist în sănătate mentală care să te îndrume în călătoria ta către o viață psihică și emoțională mai bună! Am selectat pentru tine Psihologi / Psihoterapeuți acreditați de Colegiul Psihologilor din România. Ne preocupă calitatea serviciilor oferite de specialiști, doar 20% trec interviul pentru a fi listați pe această platformă!",
      image: "/static/global/images/customer-support-man.png",
      action: {
        next: {
          title: "Începe acum!",
        },
      },
    },
    {
      id: "consent",
      slug: "consent",
      type: OnboardingStepType.CONSENT,
      title: "Termeni și condiții",
      description: "Pentru a continua te rugăm să bifezi consimţământul!",
      image: "/static/global/images/secure-consent.png",
      question: {
        required: true,
      },
      action: {
        next: {
          title: "Continuă",
        },
      },
    },
    {
      id: "name",
      slug: "name",
      type: OnboardingStepType.QUESTION,
      title: "Hai să ne cunoaștem!",
      question: {
        type: OnboardingQuestionType.TEXT,
        label: "Introdu numele tău mai jos",
        required: true,
        placeholder: "Nume..",
      },
    },
    {
      id: "timezone",
      slug: "timezone",
      type: OnboardingStepType.TIMEZONE,
      title: "În ce țară te afli?",
      question: {
        required: true,
        answers: ALL_AVAILABLE_TIMEZONES_CHOICES,
      },
    },
    {
      id: "gender",
      slug: "gender",
      type: OnboardingStepType.QUESTION,
      title: "Selectează sexul cu care te identifici",
      question: {
        type: OnboardingQuestionType.RADIO,
        required: true,
        answers: [
          {
            label: "Bărbat",
            value: "male",
          },
          {
            label: "Femeie",
            value: "female",
          },
          {
            label: "Altul",
            value: "another",
          },
        ],
      },
    },
    {
      id: "certified-psychologist",
      slug: "certified-psychologist",
      type: OnboardingStepType.QUESTION,
      title:
        "Intelegi că platforma Psiholog.ro listează doar Specialiști acreditați care oferă servicii acreditate contracost online?",
      question: {
        type: OnboardingQuestionType.RADIO,
        required: true,
        answers: [
          {
            label: "Da",
            value: "da",
          },
          {
            label: "Nu",
            value: "nu",
          },
        ],
      },
    },
    {
      id: "age",
      slug: "age",
      type: OnboardingStepType.QUESTION,
      title: "Selectează vârstă",
      question: {
        type: OnboardingQuestionType.RADIO,
        required: true,
        answers: [
          {
            label: "Sub 18",
            value: "0-18",
          },
          {
            label: "18-24",
            value: "18-24",
          },
          {
            label: "25-34",
            value: "25-34",
          },
          {
            label: "35-44",
            value: "35-44",
          },
          {
            label: "45-54",
            value: "45-54",
          },
          {
            label: "55-64",
            value: "55-64",
          },
          {
            label: "65+",
            value: "65+",
          },
        ],
      },
    },
    {
      id: "relationship-status",
      slug: "relationship-status",
      type: OnboardingStepType.QUESTION,
      title: "Statut relațional:",
      question: {
        type: OnboardingQuestionType.RADIO,
        required: true,
        answers: [
          {
            label: "Singur/ă",
            value: "single",
          },
          {
            label: "Căsătorit/ă",
            value: "married",
          },
          {
            label: "Divorțat/ă",
            value: "divorced",
          },
          {
            label: "Văduv/ă",
            value: "widowed",
          },
          {
            label: "Este complicat",
            value: "complicated",
          },
        ],
      },
    },
    {
      id: "problems",
      slug: "problems",
      type: OnboardingStepType.QUESTION,
      title: "Cu ce problemă/e te confrunți?",
      question: {
        type: OnboardingQuestionType.CHECKBOX,
        required: true,
        answers: [
          {
            label: "Insomnii",
            value: "insomnia",
          },
          {
            label: "Stres",
            value: "stress",
          },
          {
            label: "Anxietate",
            value: "anxiety",
          },
          {
            label: "Lipsa poftei de viață",
            value: "lack-of-zest-for-life",
          },
          {
            label: "Probleme relaționale",
            value: "relationship-problems",
          },
          {
            label: "Altele",
            value: "others",
          },
        ],
      },
    },
    {
      id: "issues",
      slug: "issues",
      type: OnboardingStepType.QUESTION,
      title: "Cum ai descrie provocările cu care te confrunți într-o propoziție pe scurt?",
      question: {
        type: OnboardingQuestionType.TEXTAREA,
        required: true,
        placeholder: "Descrie provocările tale..",
      },
    },
    {
      id: "expectations",
      slug: "expectations",
      type: OnboardingStepType.QUESTION,
      title: "Ce așteptări ai de la procesul psihoterapeutic?",
      description:
        "Descrie pe scurt obiectivele și așteptările tale de la viitorul tău psiholog/psihoterapeut mai jos!",
      question: {
        type: OnboardingQuestionType.TEXTAREA,
        required: true,
        placeholder: "Așteptările tale..",
      },
    },
    {
      id: "phone-number",
      slug: "phone-number",
      type: OnboardingStepType.QUESTION,
      title: "Lasă-ne un număr de telefon la care poți fi contactat:",
      description: "Introdu numărul tău de telefon mai jos:",
      question: {
        type: OnboardingQuestionType.TEXT,
        label: "Ne este necesar pentru a te putea contacta",
        required: true,
        minLength: 6,
        maxLength: 15,
      },
    },
    {
      id: "psychiatric-treatment",
      slug: "psychiatric-treatment",
      type: OnboardingStepType.QUESTION,
      title: "În trecut ai urmat un tratament psihiatric?",
      question: {
        type: OnboardingQuestionType.RADIO,
        required: true,
        answers: [
          {
            label: "Da",
            value: "da",
          },
          {
            label: "Nu",
            value: "nu",
          },
        ],
      },
    },
    {
      id: "emergency",
      slug: "emergency",
      type: OnboardingStepType.QUESTION,
      title:
        "Înțelegi faptul că această platformă nu este un serviciu de urgență gratuit decontat de stat? (Dacă te afli într-o situație de urgență te rugăm să suni la numărul 112!)",
      question: {
        type: OnboardingQuestionType.RADIO,
        required: true,
        answers: [
          {
            label: "Da",
            value: "da",
          },
          {
            label: "Nu",
            value: "nu",
          },
        ],
      },
      action: {
        next: {
          url: "/auth/register",
          title: "Inregistrează-te",
        },
      },
    },
  ];
};
