import { END } from "@redux-saga/core";
import { PayloadAction } from "@reduxjs/toolkit";
import { delay, put, race, select, take, takeLeading } from "redux-saga/effects";

import { isServer } from "@/core/utils/nextjs";
import { appActions, InitializeBasePayload, ReInitializePayload } from "./actions";
import { appSelectors } from "./selectors";
import { AppStatus } from "./types";
import { actionItemsActions } from "../actionItems/actions";
import { authActions } from "../auth/actions";
import { authSelectors } from "../auth/selectors";
import { settingsActions } from "../settings/actions";
import { siteActions } from "../site/actions";
import { SiteStatus } from "../site/types";

function* onReinitialize(action: PayloadAction<ReInitializePayload>): Generator {
  if (isServer()) {
    console.error("Cannot reinitialize the app on the server");
    return;
  }
  yield put(appActions.setStatus(AppStatus.LOADING));
  yield delay(500);
  const redirectUrl = action.payload?.redirect ?? "/";
  window.location.href = redirectUrl;
}

function* onInitializeBase(action: PayloadAction<InitializeBasePayload>): Generator {
  // Run on server and initialize the minimum required for the app to run
  const status = (yield select(appSelectors.appStatus)) as AppStatus;
  // If app is in the default/loading state, init
  if (status == AppStatus.LOADING) {
    try {
      const domain = action.payload.hostname;

      // Detect and initialize the website/cobrand
      const raced1: any = yield race({
        status: initSite(domain),
        timeout: delay(10000), // 10s
      });
      if (raced1.timeout) {
        throw new Error("timeout on initSite");
      }
      if (raced1.status != SiteStatus.CONFIGURED) {
        throw new Error("Failed to initSite as configured");
      }

      // Init auth
      const raced2: any = yield race({
        ready: initAuth(),
        timeout: delay(10000), // 10s
      });
      if (raced2.timeout) {
        console.warn("timeout on initAuth - continuing anyway");
      }

      // Mark app as ready partial
      yield put(appActions.setStatus(AppStatus.READY_PARTIAL));
    } catch (error) {
      yield put(appActions.setStatus(AppStatus.ERROR));
    }
  } else {
    yield put(appActions.setStatus(status));
  }

  // End saga if needed by the SSR
  if (action.payload?.endSaga) {
    yield put(END);
  }
}

function* onInitializeFull(): Generator {
  // Run on client and initialize the remaining parts of the app, not run on the server
  const status = (yield select(appSelectors.appStatus)) as AppStatus;
  // If app is in the default/loading state, init
  if (status == AppStatus.READY_PARTIAL) {
    // Init auth
    yield race({
      ready: initSettings(),
      timeout: delay(1000), // 10s
    });
    yield triggerOthers();
    yield put(appActions.setStatus(AppStatus.READY_FULL));
  } else {
    yield put(appActions.setStatus(status));
  }
}

function* initAuth(): Generator {
  yield put(authActions.initialize({}));
  yield take(authActions.setStatus);
}

function* initSettings(): Generator {
  yield put(settingsActions.initialize());
  yield take(settingsActions.setTimezone);
}

function* triggerOthers(): Generator {
  const isUser = (yield select(authSelectors.isUser)) as boolean;
  if (isUser) {
    yield put(actionItemsActions.initialize());
  }
}

function* initSite(domain: string): Generator {
  yield put(siteActions.initialize({ domain }));
  const action = (yield take(siteActions.setStatus)) as PayloadAction<SiteStatus>;
  return action.payload;
}

export default function* sagas(): Generator {
  yield takeLeading(appActions.initializeBase, onInitializeBase);
  yield takeLeading(appActions.initializeFull, onInitializeFull);
  yield takeLeading(appActions.reInitialize, onReinitialize);
}
