import { FC, PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { usePathname } from "next/navigation";
import { authSelectors } from "@/core/store/redux/auth/selectors";
import { isClient } from "@/core/utils/nextjs";
import { Redirect } from "../navigation/Redirect";

const AllowedPathsToRedirect = ["/providers", "/chat", "/bookings", "/actions", "/help", "/account"];

const AppDomainGuard: FC<PropsWithChildren> = ({ children }) => {
  const siteDomain = useSelector(authSelectors.siteDomain);
  const path = usePathname();
  if (isClient() && siteDomain?.matches_request === false) {
    // if the path is allowed to redirect
    for (let i = 0; i < AllowedPathsToRedirect.length; i++) {
      const allowedPath = AllowedPathsToRedirect[i];
      if (path.includes(allowedPath)) {
        return <Redirect to="/bad-domain" loading />;
      }
    }
  }
  return children;
};
export default AppDomainGuard;
