import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./common";

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery: baseQuery(),
  tagTypes: ["ChatConfig"],
  endpoints: (builder) => ({
    generateConfig: builder.mutation<any, string>({
      query: (provider_id) => ({
        url: `/api/chat-providers-customers/customers/chat/config`,
        method: "POST",
        body: { provider_id: provider_id },
      }),
      invalidatesTags: ["ChatConfig"],
    }),
    pingVideoChannel: builder.mutation<any, void>({
      query: () => ({
        url: `/api/chat-providers-customers/customers/channels/active/ping-video`,
        method: "POST",
      }),
    }),
    pingChatChannel: builder.mutation<any, void>({
      query: () => ({
        url: `/api/chat-providers-customers/customers/channels/active/ping-chat`,
        method: "POST",
      }),
    }),
  }),
});
export const { useGenerateConfigMutation, usePingChatChannelMutation, usePingVideoChannelMutation } = chatApi;
