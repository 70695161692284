import { combineReducers, UnknownAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

import { apiReducers } from "@/core/api/services";
import { isClient } from "@/core/utils/nextjs";

import { appReducerSlice } from "./app/slices";
import { authReducerSlice } from "./auth/slices";
import { chatReducerSlice } from "./chat/slices";
import { contextReducerSlice } from "./context/slices";
import { onboardingReducerSlice } from "./onboarding/slices";
import { onboardingProgressReducerSlice } from "./onboardingProgress/slices";
import { providerReducerSlice } from "./provider/slices";
import { providersReducerSlice } from "./providers/slices";
import { routerReducerSlice } from "./router/slices";
import { settingsReducerSlice } from "./settings/slices";
import { siteReducerSlice } from "./site/slices";
import { themeReducerSlice } from "./theme/slices";
import { actionItemsReducerSlice } from "./actionItems/slices";
import { featuresReducerSlice } from "./features/slices";
import { trackingReducerSlice } from "./tracking/slices";

import { ReduxState } from "../types";

const sliceReducer = {
  ...apiReducers,
  [appReducerSlice.name]: appReducerSlice.reducer,
  [authReducerSlice.name]: authReducerSlice.reducer,
  [contextReducerSlice.name]: contextReducerSlice.reducer,
  [routerReducerSlice.name]: routerReducerSlice.reducer,
  [siteReducerSlice.name]: siteReducerSlice.reducer,
  [chatReducerSlice.name]: chatReducerSlice.reducer,
  [providerReducerSlice.name]: providerReducerSlice.reducer,
  [providersReducerSlice.name]: providersReducerSlice.reducer,
  [onboardingReducerSlice.name]: onboardingReducerSlice.reducer,
  [onboardingProgressReducerSlice.name]: onboardingProgressReducerSlice.reducer,
  [themeReducerSlice.name]: themeReducerSlice.reducer,
  [settingsReducerSlice.name]: settingsReducerSlice.reducer,
  [actionItemsReducerSlice.name]: actionItemsReducerSlice.reducer,
  [featuresReducerSlice.name]: featuresReducerSlice.reducer,
  [trackingReducerSlice.name]: trackingReducerSlice.reducer,
};

export const rootReducer = (state: ReduxState, action: UnknownAction) => {
  // Prevent setting app context in state
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...(action.payload || {}), // apply delta from hydration
    };

    if (!isClient()) {
      // Custom server logic
    }

    return nextState;
  }

  return combineReducers(sliceReducer)(state, action);
};
